<template>
  <div id="smartai_product_score_manage_page" style="margin: 20px" v-cloak>
    <el-dialog
      title="得分详情"
      :visible.sync="dialogVisible"
      size="mini"
      top="5vh"
      width="80%"
    >
      <el-table
        :data="flowTableData"
        show-summary
        :summary-method="getSummaries"
        style="width: 100%"
      >
        <el-table-column prop="name" label="任务名称" width="130">
        </el-table-column>
        <el-table-column prop="desc" label="任务描述"> </el-table-column>
        <el-table-column prop="default_score" label="评分项分值" width="130">
        </el-table-column>
        <el-table-column prop="score" label="用户得分" width="130">
        </el-table-column>
      </el-table>
    </el-dialog>
    <el-tabs v-model="tabActiveName" type="border-card">
      <el-tab-pane class="tab_plane" label="首单分数" name="firstordertabpane">
        <el-input
          size="small"
          v-model="search_first_goods_id"
          style="width: 200px; margin-right: 10px"
          clearable
          placeholder="请输入产品id"
        ></el-input>
        <el-button size="small" type="primary" @click="searchFirstClick"
          >查询</el-button
        >
        <div>
          <el-table :data="firstorderTableData" style="width: 100%">
            <el-table-column prop="goods_id" label="产品id" width="100">
            </el-table-column>
            <el-table-column prop="member_name" label="用户名" width="100">
            </el-table-column>
            <el-table-column
              prop="school_student_string"
              label="学校学号"
              width="100"
            >
            </el-table-column>
            <el-table-column prop="goods_name" label="产品名称">
            </el-table-column>
            <el-table-column prop="school_name" label="学校"> </el-table-column>
            <el-table-column prop="class_name" label="班级名称">
            </el-table-column>
            <el-table-column prop="score" label="分数"> </el-table-column>
            <el-table-column label="查看得分详情">
              <template slot-scope="scope">
                <el-link
                  type="success"
                  :underline="false"
                  @click="openDetail(scope.row, 1)"
                  >查看首单详情</el-link
                >
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            style="margin-top: 15px"
            small
            background
            layout="prev, pager, next"
            @current-change="handleFirstorderCurrentChange"
            :page-size="firstorderPagesize"
            :total="firstorderTotalCount"
          >
          </el-pagination>
        </div>
      </el-tab-pane>
      <el-tab-pane
        class="tab_plane"
        label="持续出单分数"
        name="continueordertabpane"
      >
        <el-input
          size="small"
          v-model="search_continue_goods_id"
          style="width: 200px; margin-right: 10px"
          clearable
          placeholder="请输入产品id"
        ></el-input>
        <el-button size="small" type="primary" @click="searchContinueClick"
          >查询</el-button
        >
        <el-table :data="teacherTableData" style="width: 100%">
          <el-table-column prop="goods_id" label="产品id" width="100">
          </el-table-column>
          <el-table-column prop="member_name" label="用户名" width="100">
          </el-table-column>
          <el-table-column
            prop="school_student_string"
            label="学校学号"
            width="100"
          >
          </el-table-column>
          <el-table-column prop="goods_name" label="产品名称">
          </el-table-column>
          <el-table-column prop="school_name" label="学校"> </el-table-column>
          <el-table-column prop="class_name" label="班级名称">
          </el-table-column>
          <el-table-column prop="score" label="分数"> </el-table-column>

          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-link
                type="success"
                :underline="false"
                @click="openDetail(scope.row, 2)"
                >查看持续出单详情</el-link
              >
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          style="margin-top: 15px"
          small
          background
          layout="prev, pager, next"
          @current-change="handleTeacherCurrentChange"
          :page-size="teacherPagesize"
          :total="teacherTotalCount"
        >
        </el-pagination>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
import { getFirstOrderList, getProductDetail } from "@/api/admin.js";
export default {
  data() {
    return {
      search_data: {},
      tabActiveName: "firstordertabpane",
      school_list: [],
      firstorderTableData: [],
      firstorderTotalCount: 0,
      firstorderPage: 1,
      firstorderPagesize: 8,
      teacher_school_id: "",
      class_list: [],
      teacherTableData: [],
      teacherTotalCount: 0,
      teacherPage: 1,
      teacherPagesize: 8,
      dialogVisible: false,
      formLabelWidth: "120px",
      flowTableData: [],
      search_first_goods_id: "",
      search_continue_goods_id: "",
    };
  },
  methods: {
    getSummaries: function (param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index == 0) {
          sums[index] = "总分";
        } else if (index == 3) {
          let newArr = [];
          data.forEach(function (item, index, arr) {


            let obj = {
              score: item["score"],
              components_sign: item["components_sign"],
            };
            newArr.push(obj);
          });

          let sum_score = 0;
          newArr.forEach(function (item, index, arr) {
            if (
              item.components_sign == "continueorder_bad_reveiw" ||
              item.components_sign == "continueorder_store_bad_feedback"
            ) {
              sum_score = parseFloat(sum_score) - parseFloat(item.score);
            } else {
              sum_score = parseFloat(sum_score) + parseFloat(item.score);
            }
          });
          sums[index] = sum_score;
        } else if (index == 2) {
          const values = data.map((item) => Number(item[column.property]));
          if (!values.every((value) => isNaN(value))) {
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr);
              if (!isNaN(value)) {
                return Number(prev.toFixed(2)) + curr;
              } else {
                return prev;
              }
            }, 0);
          } else {
            sums[index] = "N/A";
          }
        } else {
          sums[index] = "";
        }
      });
      return sums;
    },
    searchContinueClick: function () {
      this.teacherPage = 1;
      this.getTeacherPointList();
    },
    searchFirstClick: function () {
      this.firstorderPage = 1;
      this.getFirstorderList();
    },
    openDetail: function (arr, type) {
      let vm = this;
      getProductDetail({
        goods_id: arr["goods_id"],
        school_student_string: arr["school_student_string"],
        class_id: arr["class_id"],
        school_id: arr["school_id"],
        type: type,
      }).then((res) => {
        if (res.code == 1) {
          vm.flowTableData = res.data;
          vm.dialogVisible = true;
        }
      });
    },
    handleTeacherCurrentChange: function (val) {
      this.teacherPage = val;
      this.getTeacherPointList();
    },
    handleFirstorderCurrentChange: function (val) {
      this.firstorderPage = val;
      this.getFirstorderList();
    },
    getTeacherPointList: function () {
      let vm = this;
      getFirstOrderList({
        page: vm.teacherPage,
        page_size: vm.teacherPagesize,
        goods_id: vm.search_continue_goods_id,
        type: 2,
      }).then((res) => {
        if (res.code == 1) {
          vm.teacherTableData = res.data.list;
          vm.teacherTotalCount = parseInt(res.data.total);
        }
      });
    },
    getFirstorderList: function () {
      let vm = this;
      getFirstOrderList({
        page: vm.firstorderPage,
        page_size: vm.firstorderPagesize,
        goods_id: vm.search_first_goods_id,
        type: 1,
      }).then((res) => {
        if (res.code == 1) {
          vm.firstorderTableData = res.data.list;
          vm.firstorderTotalCount = parseInt(res.data.total);
        }
      });
    },
  },
  created: function () {
    this.getFirstorderList();
    this.getTeacherPointList();
  },
};
</script>